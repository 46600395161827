export function setUrlSearchParam(param: string, value: string, search: string): string {
  let searchParams = '';
  let separator = '?';
  const params = getUrlSearchParams(search);
  if (Object.keys(params).length && Object.keys(params).includes(param)) {
    delete params[param];
  }
  Object.keys(params).forEach(key => {
    searchParams += `${separator}${key}=${params[key]}`;
    separator = '&';
  });

  searchParams += `${separator}${param}=${value}`;
  return searchParams;
}

export function getUrlSearchParams(search: string): Record<string, string> {
  const parameters = search.replace('?', '');
  const currentParameters: Record<string, string> = {};
  if (Object.keys(parameters).length) {
    const param = parameters.split('&');
    for (let i = 0; i < param.length; i++) {
      const parameter = param[i].split('=');
      currentParameters[parameter[0]] = parameter[1];
    }
  }
  return currentParameters;
}
