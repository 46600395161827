// Generated with util/create-component.js
import React from 'react';

import {
  StarFilled,
  StopOutlined,
  CrownFilled,
  BellFilled,
  PhoneFilled,
  ClockCircleFilled,
  CoffeeOutlined,
  SettingFilled,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CloseSquareFilled,
} from '@ant-design/icons';

import { ReactComponent as WheelChair } from 'src/assets/icons/wheelchair.svg';
import { ReactComponent as Utensils } from 'src/assets/icons/utensils.svg';
import { ReactComponent as HeadMask } from 'src/assets/icons/head_mask.svg';
import { ReactComponent as Allergies } from 'src/assets/icons/allergies.svg';

import { IBookingIconProps } from './BookingIcon.types';
import styles from './BookingIcon.module.scss';

const iconStyle = { style: { fontSize: 18 } };
const svgStyle = {
  width: 18,
  height: 18,
};

const BookingIcon: React.FC<IBookingIconProps> = ({ icon }) => {
  return (
    <div data-testid="BookingIcon" className={styles.root}>
      <div className={styles.icon_wrapper}>{renderBookingIcon()}</div>
    </div>
  );

  function renderBookingIcon() {
    if (!icon) return null;
    switch (icon) {
      case 'fas fa-ban':
        return <StopOutlined {...iconStyle} />;
      case 'fas fa-crown':
        return <CrownFilled {...iconStyle} />;
      case 'fas fa-star':
        return <StarFilled {...iconStyle} />;
      case 'fas fa-bell':
        return <BellFilled {...iconStyle} />;
      case 'fas fa-phone':
        return <PhoneFilled {...iconStyle} />;
      case 'fas fa-clock':
        return <ClockCircleFilled {...iconStyle} />;
      case 'fas fa-coffee':
        return <CoffeeOutlined {...iconStyle} />;
      case 'fas fa-utensils':
        return <Utensils {...svgStyle} />;
      case 'fas fa-user-cog':
        return <SettingFilled {...iconStyle} />;
      case 'fas fa-wheelchair':
        return <WheelChair {...svgStyle} />;
      case 'fas fa-allergies':
        return <Allergies {...svgStyle} />;
      case 'fas fa-head-side-mask':
        return <HeadMask {...svgStyle} />;
      case 'fas fa-backspace':
        return <CloseSquareFilled {...iconStyle} />;
      case 'fas fa-arrow-up':
        return <ArrowUpOutlined {...iconStyle} />;
      case 'fas fa-arrow-down':
        return <ArrowDownOutlined {...iconStyle} />;
      default:
        return null;
    }
  }
};

export default BookingIcon;
