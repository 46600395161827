import React, { createContext } from 'react';

import { IApiClient } from 'src/services/IApiClient';
import { IRoomsClient } from 'src/services/IRoomsClient';

export interface IApiClients {
  apiClient: IApiClient;
  roomsClient: IRoomsClient;
}

export const ApiContext = createContext<IApiClients | undefined>(undefined);

export const ApiProvider: React.FC<{ apiClient: IApiClient; roomsClient: IRoomsClient }> = ({
  children,
  apiClient,
  roomsClient,
}) => {
  const value = React.useMemo(
    () => ({
      apiClient,
      roomsClient,
    }),
    [apiClient, roomsClient],
  );

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const ApiConsumer = ApiContext.Consumer;
