// Generated with util/create-component.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Button, Row, Form, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { ILoginFormProps, IFormValues } from './LoginForm.types';
import styles from './LoginForm.module.scss';

const LoginForm: React.FC<ILoginFormProps> = ({ onLogin, onPasswordReset }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isForgotMode, setIsForgotMode] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [didResetPassword, setDidResetPassword] = useState(false);

  const submitButtonText = !isForgotMode
    ? t('login.sign_in')
    : !didResetPassword
    ? t('login.reset_password')
    : 'Email Sent';
  const forgotButtonText = isForgotMode ? 'I have a password' : t('login.forgot_password');

  return (
    <Form
      data-testid="LoginForm"
      name="login"
      className={styles.root}
      onFinish={handleSubmit}
      form={form}
      initialValues={{ email: '', password: '' }}
    >
      <Form.Item name="email" rules={[{ required: true, message: 'Please input email' }]}>
        <Input
          autoFocus
          aria-label="email"
          type="email"
          size="large"
          prefix={<UserOutlined className={styles.input_icon} />}
          placeholder={t('general.other.email')}
        />
      </Form.Item>
      {!isForgotMode && (
        <Form.Item name="password" rules={[{ required: true, message: 'Please input password' }]}>
          <Input.Password
            aria-label="password"
            size="large"
            prefix={<LockOutlined className={styles.input_icon} />}
            placeholder={t('login.password_label')}
          />
        </Form.Item>
      )}
      <Row justify="center">
        <Button
          className={styles.login_button}
          type="primary"
          htmlType="submit"
          size="large"
          shape="round"
          loading={isSubmitting}
          disabled={isForgotMode && didResetPassword}
        >
          {submitButtonText}
        </Button>
      </Row>
      <Row justify="center">
        <Button type="link" disabled={isSubmitting} onClick={() => toggleForgotMode()}>
          {forgotButtonText}
        </Button>
      </Row>
    </Form>
  );

  async function handleSubmit({ email, password }: IFormValues) {
    setSubmitting(true);
    try {
      !isForgotMode ? await onLogin(email, password) : await onPasswordReset(email);
      if (isForgotMode) {
        message.success('Password reset email sent. Please check your inbox');
        setDidResetPassword(true);
        setSubmitting(false);
      }
    } catch (error) {
      const errorMessage = isForgotMode
        ? 'An error occured, unable to reset password'
        : 'Invalid Credentials, please try again';
      message.error(errorMessage);
      setSubmitting(false);
    }
  }

  function toggleForgotMode() {
    form.resetFields(['password']);
    setIsForgotMode(forgot => !forgot);
  }
};

export default LoginForm;
