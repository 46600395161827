export function copyAndReplaceAt<T>(arr: T[], index: number, newValue: T): T[] {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export function sortByPropertyValue<T, K extends keyof T>(values: T[], property: K): T[] {
  return values.slice().sort((a, b) => {
    if (isNaN(Number(a[property])) && isNaN(Number(b[property])))
      return String(a[property]).localeCompare(String(b[property]));
    if (isNaN(Number(a[property]))) return 1;
    if (isNaN(Number(b[property]))) return -1;
    return Number(a[property]) - Number(b[property]);
  });
}
