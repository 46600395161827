// Generated with util/create-component.js
import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import locales from 'src/locales';

import styles from './LocaleSelect.module.scss';

const LocaleSelect: React.FC = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const localeOptions = locales.map(locale => ({
    value: locale.code,
    label: locale.name,
  }));

  /* Only take the language, not the full locale */
  const defaultSelectedLanguage = language.includes('-') ? language.split('-')[0] : language;

  return (
    <div data-testid="LocaleSelect" className={styles.root}>
      <Select
        className={styles.select}
        showSearch
        optionFilterProp="label"
        defaultValue={defaultSelectedLanguage}
        onChange={(code: string) => i18n.changeLanguage(code)}
        options={localeOptions}
      />
    </div>
  );
};

export default LocaleSelect;
