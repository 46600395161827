// Generated with util/create-component.js
import React from 'react';
import { Select } from 'antd';

import { useAuth } from 'src/hooks/useAuth';

import styles from './HotelSelect.module.scss';
import { IHotelSelectProps } from './HotelSelect.types';

const HotelSelect: React.FC<IHotelSelectProps> = ({ onHotelChanged }) => {
  const auth = useAuth();

  const hotelOptions = auth.user
    ? auth.user.hotels.map(hotel => ({
        value: hotel.id,
        label: hotel.name,
      }))
    : [];

  return (
    <div data-testid="HotelSelect" className={styles.root}>
      <Select
        className={styles.select}
        showSearch
        optionFilterProp="label"
        defaultValue={auth.selectedHotelId}
        onChange={handleHotelChanged}
        options={hotelOptions}
      />
    </div>
  );

  function handleHotelChanged(value: number) {
    auth.switchHotel(value);
    onHotelChanged(value);
  }
};

export default HotelSelect;
