import { IUser, TRole } from 'src/interfaces/IUser';
import { TRules } from 'src/interfaces/TRules';
import { IPermissionService } from './IPermissionService';

type TRestrictionsTable = {
  [role in TRole]: { default: boolean } & Partial<Record<TRules, boolean>>;
};

class PermissionService implements IPermissionService {
  private restrictionsTable: TRestrictionsTable = {
    admin: { default: true },
    user: { default: true },
    reception: { default: true },
    reception_no_finance: { default: true },
    housekeeper: { default: false, 'room.hotelNotes': false, 'room.guestNotes': false, 'room.housekeeperNotes': true },
    reports_bookings_customers: { default: false },
  };

  /* Note that this way of client-side access-checking is easily bypassed!
   * This should not be used for sensitive data. For sensitive data, it
   * should simply not be returned by the API back to the calling user!
   * Only use this check for non-sensitive stuff like hiding certain UI
   * elements or changing layout! */
  public userHasAccess(user: IUser, rule: TRules): boolean {
    let role = user.role;
    const systemRole = user.system_role;

    if (systemRole === 'admin' || systemRole === 'corporate_admin' || systemRole === 'white_admin') {
      role = 'admin';
    }

    /* If the role doesn't exist, deny access */
    if (!this.restrictionsTable[role]) {
      return false;
    }

    const roleRules = this.restrictionsTable[role];
    /* Use the default if the rule is not defined for the role */
    return roleRules[rule] || roleRules.default;
  }
}

export default PermissionService;
