// Generated with util/create-component.js
import React from 'react';

import cx from 'classnames';
import { ExportOutlined, MessageFilled, RightOutlined } from '@ant-design/icons';

import BookingIcon from '../../BookingIcon/BookingIcon';

import { IRoomItemProps } from './RoomItem.types';
import styles from './RoomItem.module.scss';

const iconStyle = { fontSize: 18, paddingRight: '2px', fill: '#fff' };

const RoomItem: React.FC<IRoomItemProps> = ({
  number,
  cleanStatus,
  doNotDisturb,
  hasNote,
  onClick,
  isUpcomingBookingToday,
  isCheckoutToday,
  icon,
  upcomingBookingIcon,
  roomNights,
}) => {
  return (
    <div
      data-testid="RoomItem"
      onClick={onClick}
      className={cx(styles.room_item_container, {
        [styles.inspected]: cleanStatus === 'inspected',
        [styles.clean]: cleanStatus === 'clean',
        [styles.dirty]: cleanStatus === 'dirty',
        [styles.broken]: cleanStatus === 'broken',
        [styles.doNotDisturb]: doNotDisturb,
      })}
    >
      <div className={styles.room_item_wrapper}>
        <div className={styles.room_item_content}>
          <div className={styles.room_item_details}>
            {roomNights && <div className={styles.room_nights}>{roomNights}</div>}
            <div
              className={cx(styles.room_number, {
                [styles.small_number]: number.length > 20,
                [styles.medium_number]: number.length > 4,
              })}
            >
              {number}
            </div>
            <div className={styles.icons_wrapper}>
              {hasNote && <MessageFilled style={iconStyle} data-testid="RoomItem.RoomNote" />}
              {isCheckoutToday && <ExportOutlined style={iconStyle} data-testid="RoomItem.CheckoutIcon" />}
              {icon && <BookingIcon icon={icon} />}
              {isUpcomingBookingToday && <RightOutlined style={iconStyle} data-testid="RoomItem.CheckinIcon" />}
              {upcomingBookingIcon && <BookingIcon icon={upcomingBookingIcon} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomItem;
