// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Typography, Menu, Dropdown, Modal, Input, Skeleton } from 'antd';
import {
  CheckOutlined,
  ClearOutlined,
  EyeOutlined,
  ExclamationOutlined,
  StopOutlined,
  QuestionOutlined,
  RightOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import cx from 'classnames';

import { IRoom } from 'src/services/IRoomsClient';
import { IRoomStatusCardProps } from './RoomStatusCard.types';
import styles from './RoomStatusCard.module.scss';

const RoomStatusCard: React.FC<IRoomStatusCardProps> = ({
  loading,
  roomTitle,
  cleanStatus,
  roomOccupationStatus = '',
  roomStatusLabel,
  doNotDisturb = false,
  isUpcomingBookingToday,
  isDepartureToday,
  onCleanStatusChanged,
  onDoNotDisturbSelected,
}) => {
  const [housekeeperNoteInputVisible, setHousekeeperNoteInputVisible] = React.useState(false);
  const [housekeeperNote, setHousekeeperNote] = React.useState('');
  const icon = resolveStatusIcon();
  const { t } = useTranslation();

  const headerCls = cx(styles.header, {
    [styles.clean]: cleanStatus === 'clean',
    [styles.dirty]: cleanStatus === 'dirty',
    [styles.inspected]: cleanStatus === 'inspected',
    [styles.broken]: cleanStatus === 'broken',
    [styles.do_not_disturb]: doNotDisturb,
  });

  const menu = (
    <Menu
      className={styles.change_status_menu}
      onClick={({ key }) => handleRoomCleanStatusSelect(key as IRoom['clean_status'] | 'do_not_disturb')}
    >
      <Menu.Item key="clean">
        <div className={styles.clean} />
        {t('bookings.calendar.clean_statuses.clean')}
      </Menu.Item>
      <Menu.Item key="dirty">
        <div className={styles.dirty} />
        {t('bookings.calendar.clean_statuses.dirty')}
      </Menu.Item>
      <Menu.Item key="inspected">
        <div className={styles.inspected} />
        {t('bookings.calendar.clean_statuses.inspected')}
      </Menu.Item>
      <Menu.Item key="do_not_disturb">
        <div className={styles.do_not_disturb} />
        {t('bookings.calendar.clean_statuses.do_not_disturb')}
      </Menu.Item>
      <Menu.Item key="broken">
        <div className={styles.broken} />
        {t('bookings.calendar.clean_statuses.broken')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div data-testid="RoomStatusCard" className={styles.root}>
      <div className={headerCls}>
        <div className={styles.room_title}>
          <Typography.Title level={2}>{roomTitle}</Typography.Title>
        </div>
        <div className={styles.change_status_wrapper}>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className={styles.change_status_btn} data-testid="change-clean-status">
              {icon}
            </div>
          </Dropdown>
        </div>
      </div>

      {loading && (
        <div className={styles.content}>
          <Skeleton active paragraph={{ rows: 1 }} title={true} />
        </div>
      )}
      {!loading && (
        <div className={styles.content}>
          {roomStatusLabel && (
            <div>
              {t('reports.housekeeping_report.status_td')}:{' '}
              <span className={styles.room_status}>{roomStatusLabel}</span>
            </div>
          )}
          {isUpcomingBookingToday && (
            <div>
              <RightOutlined /> {' ' + t('housekeeping.room_details.new_guests_arriving_today')}
            </div>
          )}
          {isDepartureToday && roomOccupationStatus === 'checked_in' && (
            <div>
              <ExportOutlined /> {' ' + t('housekeeping.room_details.checking_out')}
            </div>
          )}
          {isDepartureToday && roomOccupationStatus === 'checked_out' && (
            <div>
              <ExportOutlined /> {' ' + t('housekeeping.room_details.checked_out')}
            </div>
          )}
        </div>
      )}
      <Modal
        title={t('bookings.create_room_note_modal.add_room_hk_note_title')}
        visible={housekeeperNoteInputVisible}
        onCancel={handleCancelHousekeeperNote}
        destroyOnClose
        footer={[
          <Button key="hk_note_cancel" onClick={handleCancelHousekeeperNote}>
            {t('general.confirm.cancel')}
          </Button>,
          <Button
            form="hk_note"
            key="hk_note_submit"
            htmlType="submit"
            type="primary"
            onClick={handleConfirmHousekeeperNote}
            disabled={!housekeeperNote}
          >
            {t('general.buttons.save')}
          </Button>,
        ]}
      >
        <Form id="hk_note" onFinish={handleConfirmHousekeeperNote}>
          <Input
            onChange={handleHousekeeperNoteChanged}
            value={housekeeperNote}
            autoFocus
            data-testid="HousekeeperNote"
          />
        </Form>
      </Modal>
    </div>
  );

  function resolveStatusIcon() {
    const style = { fontSize: '24px' };

    // The do not disturb status takes precedence over any other status
    if (doNotDisturb) return <StopOutlined style={style} />;
    if (cleanStatus === 'clean') return <CheckOutlined style={style} />;
    if (cleanStatus === 'dirty') return <ClearOutlined style={style} />;
    if (cleanStatus === 'inspected') return <EyeOutlined style={style} />;
    if (cleanStatus === 'broken') return <ExclamationOutlined style={style} />;

    return <QuestionOutlined style={style} />;
  }

  function handleRoomCleanStatusSelect(status: IRoom['clean_status'] | 'do_not_disturb') {
    if (status === 'do_not_disturb') {
      return onDoNotDisturbSelected();
    }

    if (status === 'broken') {
      setHousekeeperNoteInputVisible(true);
      return;
    }

    return onCleanStatusChanged(status);
  }

  function handleConfirmHousekeeperNote() {
    if (!housekeeperNote) return;
    onCleanStatusChanged('broken', housekeeperNote);
    setHousekeeperNote('');
    setHousekeeperNoteInputVisible(false);
  }

  function handleCancelHousekeeperNote() {
    setHousekeeperNoteInputVisible(false);
  }

  function handleHousekeeperNoteChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setHousekeeperNote(e.target.value);
  }
};

export default RoomStatusCard;
