import { IApiClient } from 'src/services/IApiClient';
import { IRoomsClient, IRoom, IRoomDetails, IRoomFilters, IRoomCleanStatusBody } from './IRoomsClient';

export default class RoomsClient implements IRoomsClient {
  constructor(private readonly apiClient: IApiClient) {}

  async getRooms(hotelId: number, filters: IRoomFilters = {}): Promise<IRoom[]> {
    const query = [];
    if (filters.zone_id !== undefined) query.push(`zone_ids=${filters.zone_id}`);
    if (filters.clean_status !== undefined) query.push(`clean_status=${filters.clean_status}`);
    if (filters.do_not_disturb !== undefined) query.push(`do_not_disturb=${filters.do_not_disturb}`);

    const queryString = query.join('&');

    const response = await this.apiClient.call<{ rooms: IRoom[] }>(
      `hotels/${hotelId}/rooms/hk_rooms_filter?include_booking_details=true`,
      {
        queryString,
      },
    );

    return response.rooms;
  }

  async getRoom(hotelId: number, roomId: number): Promise<IRoomDetails> {
    return this.apiClient.call<IRoomDetails>(`hotels/${hotelId}/rooms/${roomId}`);
  }

  async setRoomCleanStatus(
    hotelId: number,
    roomId: number,
    { cleanStatus, housekeeperNote, doNotDisturb }: IRoomCleanStatusBody,
  ): Promise<IRoom> {
    const response = await this.apiClient.call<{ room: IRoom; success: boolean }>(
      `hotels/${hotelId}/rooms/${roomId}/change_room_clean_status`,
      {
        method: 'POST',
        body: {
          clean_status: cleanStatus,
          housekeeper_note: housekeeperNote,
          // If doNotDisturb is not explicitly set to true, we want to default to false
          do_not_disturb: doNotDisturb || false,
        },
      },
    );

    if (!response.success) {
      // TODO: Figure out if and when the API may respond with a success === false
      // and what it returns as a body in that case
      throw new Error('Unable to change room clean status');
    }

    return response.room;
  }
}
