// Generated with util/create-component.js
import React from 'react';

import { useAuth } from 'src/hooks/useAuth';
import { useRooms } from 'src/hooks/useRooms';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import RoomsList from 'src/components/RoomsList/RoomsList';
import PageLayout from 'src/components/PageLayout/PageLayout';
import NotAuthenticated from 'src/pages/NotAuthenticated/NotAuthenticated';

import { IIndexPageProps } from './IndexPage.types';
import styles from './IndexPage.module.scss';

const pageLayoutProps = {
  className: styles.root,
};

const IndexPage: React.FC<IIndexPageProps> = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { data = [], isError, error, isLoading } = useRooms();

  if (!auth.user) {
    return <NotAuthenticated />;
  }

  if (isLoading) {
    return (
      <PageLayout {...pageLayoutProps}>
        <div className={styles.loading}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          <div>{`${t('general.other.loading')}...`}</div>
        </div>
      </PageLayout>
    );
  }

  if (isError) {
    return (
      <PageLayout>
        <div className={styles.error}>
          {error && (
            <span>
              {t('general.titles.server_error')}: {error}
            </span>
          )}
          {!error && (
            <span>{t('website_settings.alert.server_error_try_again_later_or_contact_support_team_js_str')}</span>
          )}
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout data-testid="IndexPage" className={styles.root}>
      <RoomsList roomsList={data} />
    </PageLayout>
  );
};

export default IndexPage;
