import env from 'src/env';
import { AuthError } from 'src/errors/AuthError';
import { IUser } from 'src/interfaces/IUser';
import { TRules } from 'src/interfaces/TRules';
import { IAuthClient, IStoredSession } from './IAuthClient';
import { IPermissionService } from './IPermissionService';
import { IStorage } from './IStorage';

interface ILoginResponseBody {
  session: {
    token: string;
    ttl: number;
    user: IUser;
  };
}

type TExpireCallback = (expiredSession: IStoredSession) => void;
type TDeregisterFn = () => void;

export default class AuthClient implements IAuthClient {
  private sessionExpiredCallbacks: TExpireCallback[] = [];
  private SESSION_KEY = 'session';
  private HOTEL_ID_KEY = 'selected_hotel';
  private session: IStoredSession | null = null;
  private selectedHotel = -1;
  private baseUrl = env.apiBaseUrl;

  constructor(private readonly storage: IStorage, private readonly permissions: IPermissionService) {
    const storedSession = this.getStoredSession();
    if (!storedSession) return;
    if (storedSession.exp < Date.now()) {
      this.clearStoredSession();
      return;
    }

    this.session = storedSession;
    this.resolveSelectedHotelId();
  }

  async login(
    email: string,
    password: string,
  ): Promise<{ user: IUser; token: string; exp: number; selectedHotelId: number }> {
    const response: ILoginResponseBody = await fetch(`${this.baseUrl}/sessions`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ session: { email, password } }),
    }).then(res => res.json());

    const session = {
      token: response.session.token,
      exp: Date.now() + response.session.ttl * 1000,
      user: response.session.user,
    };

    this.storeSession(session);
    this.session = session;
    this.resolveSelectedHotelId();
    return { ...session, selectedHotelId: this.selectedHotel };
  }

  logout(): void {
    this.notifySessionExpired(this.session);
    this.clearStoredSession();
    this.session = null;
  }

  // TODO: Register a callback in the AuthProvider to automatically de-auth the user upon expiry of the token
  public onSessionExpired(cb: TExpireCallback): TDeregisterFn {
    this.sessionExpiredCallbacks.push(cb);

    return () => {
      const callbackIndex = this.sessionExpiredCallbacks.findIndex(i => i === cb);
      this.sessionExpiredCallbacks.splice(callbackIndex, 1);
    };
  }

  public getSession(): IStoredSession | null {
    return this.session;
  }

  public getSelectedHotelId(): number {
    return this.selectedHotel;
  }

  public setSelectedHotelId(hotelId: number): void {
    if (!this.session || !this.session.user.hotels.find(hotel => hotel.id === hotelId)) {
      throw new Error('Not allowed to switch to hotel ' + hotelId);
    }

    this.selectedHotel = hotelId;
    this.storage.set(this.HOTEL_ID_KEY, hotelId);
  }

  /**
   * Verifies the currently stored session. Returns true if the session is valid, but
   * throws an AuthError if the session is invalid.
   */
  public verifyHasValidSession(): boolean {
    const session = this.getSession();
    if (!session) {
      throw new AuthError('no_session');
    }

    if (session.exp < Date.now()) {
      throw new AuthError('session_expired');
    }

    return true;
  }

  public hasAccess(rule: TRules): boolean {
    const session = this.session;
    if (!session || !session.user || session.exp < Date.now()) {
      return false;
    }

    return this.permissions.userHasAccess(session.user, rule);
  }

  private notifySessionExpired(session: IStoredSession | null) {
    if (!session) return;
    this.sessionExpiredCallbacks.forEach(cb => cb(session));
  }

  private storeSession(session: IStoredSession): void {
    this.storage.set(this.SESSION_KEY, session);
  }

  private getStoredSession(): IStoredSession | null {
    return this.storage.get(this.SESSION_KEY);
  }

  private clearStoredSession(): void {
    return this.storage.remove(this.SESSION_KEY);
  }

  private resolveSelectedHotelId(): void {
    if (!this.session) return;
    const storedSelectedHotelId = this.storage.get<number>(this.HOTEL_ID_KEY);
    if (!storedSelectedHotelId || !this.session.user.hotels.find(hotel => hotel.id === storedSelectedHotelId)) {
      // There is no stored selected hotel, or the user no longer has access to the one stored. So we default to the first hotel.
      this.setSelectedHotelId(this.session.user.hotels[0].id);
    } else {
      this.setSelectedHotelId(storedSelectedHotelId);
    }
  }

  async resetPassword(email: string): Promise<void> {
    const response = await fetch(`${this.baseUrl}/users/forgot_password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    }).then(res => res.json());
    if (!response.success) {
      throw new Error('Error');
    }
    return response;
  }
}
