// Generated with util/create-component.js
import React from 'react';
import { Card, Typography, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import BookingIcon from '../BookingIcon/BookingIcon';

import { IRoomBookingCardProps } from './RoomBookingCard.types';
import styles from './RoomBookingCard.module.scss';

const { Text } = Typography;

const RoomBookingCard: React.FC<IRoomBookingCardProps> = ({ title, bookingRoom, loading }) => {
  const { t } = useTranslation();

  return (
    <Card data-testid="RoomBookingCard" className={styles.root} size="small" title={title}>
      {loading && <Skeleton active paragraph={{ rows: 2 }} title={false} />}
      {!loading && !bookingRoom && <Text>None</Text>}
      {!loading && bookingRoom && (
        <React.Fragment>
          <Text className={styles.guests}>
            {t('reports.general.types_fo_reports.guests')}:{' '}
            {bookingRoom.number_of_adults + bookingRoom.number_of_children}
          </Text>
          <Text className={styles.name}>{`${bookingRoom.guest.first_name} ${bookingRoom.guest.last_name}`}</Text>
          <Text className={styles.dates}>
            {dayjs(bookingRoom.checkin_date).format('DD.MM.YYYY')}
            {' - '}
            {dayjs(bookingRoom.checkout_date).format('DD.MM.YYYY')}
          </Text>
          {bookingRoom.icon && <BookingIcon icon={bookingRoom.icon} />}
        </React.Fragment>
      )}
    </Card>
  );
};

export default RoomBookingCard;
