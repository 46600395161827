import { useQueryClient, useMutation, UseMutationResult } from 'react-query';

import { IRoom } from 'src/services/IRoomsClient';
import { useApi } from './useApi';
import { useAuth } from './useAuth';
import { message } from 'antd';

interface IMutationBody {
  id: IRoom['id'];
  cleanStatus: IRoom['clean_status'];
  housekeeperNote?: string;
  doNotDisturb?: boolean;
}

export const useMutateRoomCleanStatus = (): UseMutationResult<IRoom, unknown, IMutationBody> => {
  const { selectedHotelId } = useAuth();
  const queryClient = useQueryClient();
  const { roomsClient } = useApi();

  return useMutation(
    ({ id, ...params }: IMutationBody) => roomsClient.setRoomCleanStatus(selectedHotelId, id, params),
    {
      onMutate: ({ id, cleanStatus, housekeeperNote, doNotDisturb }) => {
        const query = [selectedHotelId, 'rooms', id];
        const cachedRoom = queryClient.getQueryData<IRoom>(query);

        queryClient.setQueryData(query, {
          ...cachedRoom,
          clean_status: cleanStatus,
          housekeeper_note: housekeeperNote,
          do_not_disturb: doNotDisturb,
        });

        return () => queryClient.setQueryData(query, cachedRoom);
      },
      onSuccess: data => {
        const query = [selectedHotelId, 'rooms', data.id];
        const cachedRoom = queryClient.getQueryData<IRoom>(query);
        queryClient.setQueryData(query, { ...cachedRoom, ...data });
      },
      onError: (e: Error) => {
        message.error(e.toString());
      },
      onSettled: (data, error, { id }) => {
        // Also invalidate the specific room
        queryClient.invalidateQueries([selectedHotelId, 'rooms', id]);
      },
    },
  );
};
