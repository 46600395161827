// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../components/PageLayout/PageLayout';

import { INotAuthenticatedProps } from './NotAuthenticated.types';
import styles from './NotAuthenticated.module.scss';

const NotAuthenticated: React.FC<INotAuthenticatedProps> = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <div data-testid="NotAuthenticated" className={styles.root}>
        {t('housekeeping.general.error.not_authenticated')}
      </div>
    </PageLayout>
  );
};

export default NotAuthenticated;
