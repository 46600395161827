import React, { createContext, Reducer } from 'react';
import { IAuthClient } from 'src/services/IAuthClient';
import { TRules } from 'src/interfaces/TRules';
import { IUser } from 'src/interfaces/IUser';

interface IAuthState {
  user: IUser | null;
  token: string | null;
  exp: number;
  selectedHotelId: number;
}

type TAction =
  | { type: 'logged_in'; payload: { user: IUser; token: string; exp: number; selectedHotelId: number } }
  | { type: 'logged_out' }
  | { type: 'switch_hotel'; payload: { hotelId: number } };

export type TAuth = IAuthState & {
  isAuthenticated: boolean;
  _client: IAuthClient;
  login: (email: string, username: string) => Promise<void>;
  logout: () => Promise<void>;
  switchHotel: (hotelId: number) => void;
  hasAccess: (rule: TRules) => boolean;
  resetPassword: (email: string) => Promise<void>;
};

const initialAuthState: IAuthState = {
  user: null,
  token: null,
  exp: -1,
  selectedHotelId: -1,
};

const authReducer: Reducer<IAuthState, TAction> = (state, action) => {
  if (action.type === 'logged_in') {
    return { ...state, ...action.payload };
  }

  if (action.type === 'logged_out') {
    return { ...initialAuthState };
  }

  if (action.type === 'switch_hotel') {
    return { ...state, selectedHotelId: action.payload.hotelId };
  }

  return state;
};

export const AuthContext = createContext<TAuth | undefined>(undefined);

export const AuthProvider: React.FC<{ authClient: IAuthClient }> = ({ children, authClient }) => {
  const storedSession = authClient.getSession();
  const selectedHotelId = authClient.getSelectedHotelId();

  const [state, dispatch] = React.useReducer(authReducer, {
    user: storedSession?.user || null,
    token: storedSession?.token || null,
    exp: storedSession?.exp || -1,
    selectedHotelId,
  });

  const isAuthenticated = !!state.user;

  const login = async (email: string, password: string) => {
    const response = await authClient.login(email, password);
    dispatch({ type: 'logged_in', payload: response });
  };

  const logout = async () => {
    authClient.logout();
    dispatch({ type: 'logged_out' });
  };

  const switchHotel = (hotelId: number) => {
    authClient.setSelectedHotelId(hotelId);
    dispatch({ type: 'switch_hotel', payload: { hotelId } });
  };

  const hasAccess = (rule: TRules) => {
    return authClient.hasAccess(rule);
  };

  const resetPassword = async (email: string) => {
    await authClient.resetPassword(email);
  };

  const value: TAuth = {
    ...state,
    isAuthenticated,
    _client: authClient,
    login,
    logout,
    switchHotel,
    hasAccess,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;
