// Generated with util/create-component.js
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import PageLayout from 'src/components/PageLayout/PageLayout';

import { IPageNotFoundProps } from './PageNotFound.types';
import styles from './PageNotFound.module.scss';

const PageNotFound: React.FC<IPageNotFoundProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <PageLayout>
      <div data-testid="PageNotFound" className={styles.root}>
        <div className={styles.number}>404</div>
        <div>{t('housekeeping.general.error.page_not_found')}</div>
        <Button
          className={styles.redirect_button}
          type="primary"
          htmlType="submit"
          size="large"
          shape="round"
          onClick={() => history.push('/')}
        >
          {t('website_settings.navigation.home_option')}
        </Button>
      </div>
    </PageLayout>
  );
};

export default PageNotFound;
