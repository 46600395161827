// Generated with util/create-component.js
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Radio, Row } from 'antd';
import { getUrlSearchParams, setUrlSearchParam } from 'src/utils/urlParams';

import styles from './RoomsList.module.scss';
import { IRoomsListProps, TRoomGroupStatus } from './RoomsList.types';
import RoomItem from './RoomItem/RoomItem';
import { IRoom } from 'src/services/IRoomsClient';
import { sortByPropertyValue } from 'src/utils/array';

const RoomsList: React.FC<IRoomsListProps> = ({ roomsList }) => {
  React.useEffect(() => {
    const params = getUrlSearchParams(location.search);
    if (params['filter']) {
      setFilter(params['filter'] as TRoomGroupStatus);
    }
  }, []);

  const { t } = useTranslation();
  const history = useHistory();

  const [filter, setFilter] = useState<TRoomGroupStatus | 'all'>('all');

  const options = [
    { label: t('general.filters.all'), value: 'all' },
    { label: t('bookings.calendar.clean_statuses.dirty'), value: 'dirty' },
    { label: t('bookings.calendar.clean_statuses.inspected'), value: 'inspected' },
    { label: t('bookings.calendar.clean_statuses.clean'), value: 'clean' },
    { label: t('bookings.calendar.clean_statuses.broken'), value: 'broken' },
  ];

  const filteredRoomsList = filterRoomsByCleanStatus(filter);

  return (
    <div data-testid="RoomsList" className={styles.root}>
      <Row justify="center" align="middle">
        <Col span={24} className={styles.filters}>
          <Radio.Group
            options={options}
            onChange={e => handleChangeFilter(e.target.value)}
            value={filter}
            optionType="button"
            buttonStyle="solid"
            size={window.innerWidth < 767 ? 'small' : 'middle'}
          />
        </Col>
      </Row>
      <Row>
        {filteredRoomsList.map(room => (
          <RoomItem
            key={room.id}
            hasNote={!!room.housekeeper_note}
            cleanStatus={room.clean_status}
            number={room.number}
            onClick={() => handleOpenRoomDetails(room.id)}
            doNotDisturb={room.do_not_disturb}
            isUpcomingBookingToday={room.is_upcoming_booking_today}
            isCheckoutToday={isCheckoutToday(room)}
            icon={room.icon}
            upcomingBookingIcon={room.upcoming_booking_icon}
            roomNights={getRoomSpentNights(room)}
          />
        ))}
      </Row>
      {!filteredRoomsList.length && (
        <Row justify="center" className={styles.helper_message}>
          {t('bookings.calendar.clean_statuses.no_rooms')}
        </Row>
      )}
    </div>
  );

  function handleChangeFilter(filter: TRoomGroupStatus) {
    setFilter(filter);
    handleUpdateUrlParam('filter', filter);
  }

  function handleUpdateUrlParam(param: string, value: string) {
    const search = setUrlSearchParam(param, value, location.search);
    history.replace(search);
  }

  function filterRoomsByCleanStatus(status: TRoomGroupStatus) {
    if (status === 'all') {
      return sortByPropertyValue(roomsList, 'number');
    } else {
      const filteredRoomsList = roomsList.filter(room => room.clean_status === status);
      return sortByPropertyValue(filteredRoomsList, 'number');
    }
  }

  function handleOpenRoomDetails(roomId: IRoom['id']) {
    history.push(`/rooms/${roomId}`);
  }

  function getRoomSpentNights(room: IRoom): string {
    if (!room || !room.checkout_date || !room.checkin_date) {
      return '';
    }
    const nightsSpent = Math.floor((new Date().getTime() - new Date(room.checkin_date).getTime()) / (1000 * 3600 * 24));
    const totalNights = Math.floor(
      (new Date(room.checkout_date).getTime() - new Date(room.checkin_date).getTime()) / (1000 * 3600 * 24),
    );
    return `${nightsSpent}/${totalNights}`;
  }

  function isCheckoutToday(room: IRoom) {
    if (!room) return false;
    const checkoutDate = new Date(room.checkout_date);
    const today = new Date();
    return (
      checkoutDate.getFullYear() === today.getFullYear() &&
      checkoutDate.getMonth() === today.getMonth() &&
      checkoutDate.getDate() === today.getDate()
    );
  }
};

export default RoomsList;
