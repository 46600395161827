// This was copied from the Angular project, the locales are defined on the client.

const locales = [
  {
    code: 'en',
    name: 'English',
    flag: '🇺🇸',
    is_production: true,
  },
  {
    code: 'de',
    name: 'Deutsch',
    flag: '🇩🇪',
    is_production: true,
  },
  {
    code: 'el',
    name: 'Ελληνικά',
    flag: '🇬🇷',
    is_production: true,
  },
  {
    code: 'es',
    name: 'Español',
    flag: '🇪🇸',
    is_production: true,
  },
  {
    code: 'fr',
    name: 'Français',
    flag: '🇫🇷',
    is_production: true,
  },
  {
    code: 'id',
    name: 'Indonesian',
    flag: '🇮🇩',
    is_production: true,
  },
  {
    code: 'it',
    name: 'Italiano',
    flag: '🇮🇹',
    is_production: true,
  },
  {
    code: 'pt',
    name: 'Português',
    flag: '🇵🇹',
    is_production: true,
  },
  {
    code: 'ro',
    name: 'Română',
    flag: '🇷🇴',
    is_production: true,
  },
  {
    code: 'sl-SI',
    name: 'Slovenščina',
    flag: '🇸🇮',
    is_production: true,
  },
  {
    code: 'th',
    name: 'ไทย',
    flag: '🇹🇭',
    is_production: true,
  },
  {
    code: 'is',
    name: 'Íslenska',
    flag: '🇮🇸',
    is_production: true,
  },
];

export default locales;
