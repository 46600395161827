import { useAuth } from './useAuth';
import { IRoom } from 'src/services/IRoomsClient';
import { useQueryClient, useQuery, UseQueryResult } from 'react-query';
import { useApi } from 'src/hooks/useApi';

export const useRooms = (): UseQueryResult<IRoom[], unknown> => {
  const { selectedHotelId } = useAuth();
  const queryClient = useQueryClient();
  const { roomsClient } = useApi();

  return useQuery([selectedHotelId, 'rooms'], () => roomsClient.getRooms(selectedHotelId), {
    onSuccess: rooms => {
      rooms.forEach(room => {
        const currentRoom = queryClient.getQueryData<IRoom>([selectedHotelId, 'rooms', room.id]);
        queryClient.setQueryData([selectedHotelId, 'rooms', room.id], { ...currentRoom, ...room });
      });
    },
  });
};
