// Generated with util/create-component.js
import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Typography } from 'antd';

import LoginForm from 'src/components/LoginForm/LoginForm';
import { ReactComponent as Logo } from 'src/assets/tbf_logo.svg';
import { useAuth } from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';

import { ILoginPageProps } from './LoginPage.types';
import styles from './LoginPage.module.scss';

const { Title } = Typography;

const LoginPage: React.FC<ILoginPageProps> = () => {
  const location = useLocation<{ from: { pathname: string } }>();
  const auth = useAuth();
  const { t } = useTranslation();

  const { from } = location.state || { from: { pathname: '/' } };

  if (auth.isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <div data-testid="LoginPage" className={styles.root}>
      <div className={styles.login_container}>
        <div className={styles.logo_container}>
          <Logo />
          <Title className={styles.title}>{t('housekeeping.general.housekeeping')}</Title>
        </div>
        <LoginForm onLogin={handleLogin} onPasswordReset={handlePasswordReset} />
      </div>
    </div>
  );

  async function handleLogin(email: string, password: string) {
    await auth.login(email, password);
  }

  async function handlePasswordReset(email: string) {
    await auth.resetPassword(email);
  }
};

export default LoginPage;
