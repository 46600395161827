// Generated with util/create-component.js
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cx from 'classnames';

import styles from './GlobalLoader.module.scss';

import { useIsFetching } from 'react-query';

const GlobalLoader: React.FC = () => {
  const isFetching = useIsFetching();

  return (
    <div data-testid="GlobalLoader" className={cx(styles.root, { [styles.visible]: isFetching > 0 })}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );
};

export default GlobalLoader;
