// Generated with util/create-component.js
import React from 'react';
import { Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/useAuth';
import { useRoom } from 'src/hooks/useRoom';
import { useMutateRoomCleanStatus } from 'src/hooks/useMutateRoomCleanStatus';

import { IRoom } from 'src/services/IRoomsClient';
import RoomBookingCard from 'src/components/RoomBookingCard/RoomBookingCard';
import RoomStatusCard from 'src/components/RoomStatusCard/RoomStatusCard';
import PageLayout from 'src/components/PageLayout/PageLayout';
import NotesAccordion from 'src/components/NotesAccordion/NotesAccordion';

import styles from './RoomDetailsPage.module.scss';

const RoomDetailsPage: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { id: roomId } = useParams<{ id: string }>();
  const { data: room, isLoading, isFetching } = useRoom(parseInt(roomId, 10));
  const { mutate } = useMutateRoomCleanStatus();

  const pageLayoutProps = {
    className: styles.root,
  };

  if (isLoading) {
    return (
      <PageLayout {...pageLayoutProps}>
        <div className={styles.room_loading}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          <div>{`${t('general.other.loading')}...`}</div>
        </div>
      </PageLayout>
    );
  }

  if (!room) {
    return (
      <PageLayout {...pageLayoutProps}>
        <div className={styles.room_loading}>{t('housekeeping.room_details.room_not_found')}</div>
      </PageLayout>
    );
  }

  const isGuestChangeToday = isDepartureToday() && isUpcomingBookingToday();
  const housekeeperNotes = getHouseKeeperNotes();
  const guestNotes = getGuestNotes();
  const hotelNotes = getHotelNotes();
  const roomOccupationStatus = room.current_booking_room?.occupation_status;

  return (
    <PageLayout {...pageLayoutProps}>
      <Row className={styles.room_status}>
        <Col span={24}>
          <RoomStatusCard
            roomTitle={room.number}
            cleanStatus={room.clean_status}
            doNotDisturb={room.do_not_disturb}
            roomOccupationStatus={roomOccupationStatus}
            roomStatusLabel={getRoomStatusLabel()}
            isUpcomingBookingToday={isUpcomingBookingToday()}
            onCleanStatusChanged={handleRoomCleanStatusChanged}
            onDoNotDisturbSelected={handleDoNotDisturbSelected}
            isDepartureToday={isDepartureToday()}
            loading={isFetching && !room.current_booking_room && !room.upcoming_booking_room}
          />
        </Col>
      </Row>
      <Row className={styles.room_bookings} gutter={8}>
        <Col span={12}>
          <RoomBookingCard
            title={t('housekeeping.room_details.departure')}
            loading={isFetching && !room.current_booking_room}
            bookingRoom={room.current_booking_room || null}
          />
        </Col>
        <Col span={12}>
          <RoomBookingCard
            title={t('housekeeping.room_details.arriving')}
            loading={isFetching && !room.upcoming_booking_room}
            bookingRoom={room.upcoming_booking_room || null}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <NotesAccordion housekeeperNotes={housekeeperNotes} hotelNote={hotelNotes} guestNote={guestNotes} />
        </Col>
      </Row>
    </PageLayout>
  );

  async function handleRoomCleanStatusChanged(status: IRoom['clean_status'], note = '') {
    mutate({ id: parseInt(roomId, 10), cleanStatus: status, housekeeperNote: note });
  }

  async function handleDoNotDisturbSelected() {
    if (room) {
      mutate({
        id: parseInt(roomId, 10),
        cleanStatus: room.clean_status,
        housekeeperNote: room.housekeeper_note || '',
        doNotDisturb: true,
      });
    }
  }

  function getSpentNights(): number {
    if (!room || !room.current_booking_room) {
      return 0;
    }
    const nightsSpent = Math.floor(
      (new Date().getTime() - new Date(room.current_booking_room.checkin_date).getTime()) / (1000 * 3600 * 24),
    );
    return nightsSpent;
  }

  function isDepartureToday(): boolean {
    if (!room || !room.current_booking_room) return false;
    if (!room.upcoming_booking_room) {
      return room.current_booking_room.number_of_nights <= getSpentNights();
    }
    return (
      room.current_booking_room.number_of_nights <= getSpentNights() &&
      room.upcoming_booking_room.occupation_status !== 'checked_in'
    );
  }

  function isUpcomingBookingToday(): boolean {
    if (!room) return false;
    if (
      room.current_booking_room &&
      room.current_booking_room.occupation_status === 'pending' &&
      new Date().toDateString() === new Date(room.current_booking_room.checkin_date).toDateString()
    )
      return true;
    if (!room.upcoming_booking_room) return false;
    return (
      new Date().toDateString() === new Date(room.upcoming_booking_room.checkin_date).toDateString() &&
      room.upcoming_booking_room.occupation_status === 'pending'
    );
  }

  function getRoomStatusLabel(): string {
    if (!room) return '';
    if (!room.current_booking_room && room.upcoming_booking_room) return room.upcoming_booking_room.status;
    if (!room.current_booking_room) return '';
    if (room.current_booking_room.occupation_status === 'checked_out' && !room.upcoming_booking_room) {
      return t('housekeeping.room_details.departed');
    }

    if (room.current_booking_room.occupation_status === 'checked_out' && room.upcoming_booking_room) {
      if (room.upcoming_booking_room.occupation_status === 'checked_in') {
        return `0/${room.upcoming_booking_room.number_of_nights}`;
      } else {
        return room.upcoming_booking_room.status;
      }
    }
    if (room.current_booking_room.occupation_status === 'checked_in') {
      const spentNights = getSpentNights();
      return `${spentNights}/${room.current_booking_room.number_of_nights}`;
    }
    return room.current_booking_room.status;
  }

  function getHouseKeeperNotes(): IRoom['housekeeper_note'][] {
    if (!room || !auth.hasAccess('room.housekeeperNotes')) return [];
    const housekeeperNotes = [];

    if (room.housekeeper_note) housekeeperNotes.push(room.housekeeper_note);
    if (!isGuestChangeToday && room.current_booking_room?.housekeeping_note)
      housekeeperNotes.push(room.current_booking_room.housekeeping_note);
    if (isGuestChangeToday && room.upcoming_booking_room?.housekeeping_note)
      housekeeperNotes.push(room.upcoming_booking_room.housekeeping_note);
    return housekeeperNotes;
  }

  function getHotelNotes() {
    if (!room || !auth.hasAccess('room.hotelNotes')) return '';
    if (!isGuestChangeToday && room.current_booking_room?.hotel_note) return room.current_booking_room.hotel_note;
    if (isGuestChangeToday && room.upcoming_booking_room?.hotel_note) return room.upcoming_booking_room.hotel_note;
    return '';
  }

  function getGuestNotes() {
    if (!room || !auth.hasAccess('room.guestNotes')) return '';
    if (!isGuestChangeToday && room.current_booking_room?.note) return room.current_booking_room.note;
    if (isGuestChangeToday && room.upcoming_booking_room?.note) return room.upcoming_booking_room.note;
    return '';
  }
};

export default RoomDetailsPage;
