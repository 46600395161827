import { encode, decode } from 'js-base64';
import { IStorage } from './IStorage';
import { LOCALSTORAGE_KEY_PREFIX } from 'src/constants';

export default class LocalStorage implements IStorage {
  get<T>(key: string): T | null {
    const item = window.localStorage.getItem(`${LOCALSTORAGE_KEY_PREFIX}${key}`);

    if (!item) {
      return null;
    }

    return JSON.parse(decode(item));
  }

  set<T>(key: string, data: T): void {
    if (data === undefined) {
      return this.remove(key);
    }

    window.localStorage.setItem(`${LOCALSTORAGE_KEY_PREFIX}${key}`, encode(JSON.stringify(data)));
  }

  exists(key: string): boolean {
    return this.get(key) !== null;
  }

  remove(key: string): void {
    window.localStorage.removeItem(`${LOCALSTORAGE_KEY_PREFIX}${key}`);
  }
}
