// Generated with util/create-component.js
import React from 'react';
import cx from 'classnames';

import { IPageLayoutProps } from './PageLayout.types';
import styles from './PageLayout.module.scss';
import Nav from '../Nav/Nav';

const PageLayout: React.FC<IPageLayoutProps> = ({ children, className }) => {
  return (
    <div data-testid="PageLayout" className={cx(styles.root, className)}>
      <header>
        <Nav />
      </header>
      <section className={styles.content}>{children}</section>
    </div>
  );
};

export default PageLayout;
