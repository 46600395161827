import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { AuthProvider } from './providers/AuthProvider';
import AuthClient from './services/AuthClient';
import LocalStorage from './services/LocalStorage';
import PermissionService from './services/PermissionService';
import ApiClient from './services/ApiClient';
import { ApiProvider } from './providers/ApiProvider';

import queryClient from './queryClient';
import RoomsClient from './services/RoomsClient';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    ...(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE && {
      tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    }),
  });
}

const authClient = new AuthClient(new LocalStorage(), new PermissionService());
const apiClient = new ApiClient(authClient);
const roomsClient = new RoomsClient(apiClient);

/* Strict Mode does not work with Antd right now. See https://github.com/ant-design/ant-design/issues/22493 */
ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <AuthProvider authClient={authClient}>
      <ApiProvider apiClient={apiClient} roomsClient={roomsClient}>
        <App />
      </ApiProvider>
    </AuthProvider>
    <ReactQueryDevtools position="bottom-right" />
  </QueryClientProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
