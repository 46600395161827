import { useContext } from 'react';
import { AuthContext, TAuth } from '../providers/AuthProvider';

export const useAuth = (): TAuth => {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error('useAuth requires an AuthContext');
  }

  return ctx;
};
