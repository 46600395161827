// Generated with util/create-component.js
import React from 'react';
import { Collapse, Typography } from 'antd';

import { INotesAccordionProps } from './NotesAccordion.types';
import styles from './NotesAccordion.module.scss';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;
const { Text } = Typography;

const NotesAccordion: React.FC<INotesAccordionProps> = ({ housekeeperNotes, hotelNote, guestNote }) => {
  const { t } = useTranslation();

  return (
    <Collapse className={styles.root} accordion defaultActiveKey="housekeeperNotes">
      {housekeeperNotes.length && (
        <Panel header={t('general.tooltip.housekeeping_note')} key="housekeeperNotes">
          {housekeeperNotes.map((housekeeperNote, ind) => (
            <Text key={`${ind}-${housekeeperNote}`}>
              {housekeeperNote} <br />
            </Text>
          ))}
        </Panel>
      )}
      {hotelNote && (
        <Panel header={t('general.tooltip.hotel_note')} key="hotelNote">
          <Text>{hotelNote}</Text>
        </Panel>
      )}
      {guestNote && (
        <Panel header={t('general.tooltip.guest_note')} key="guestNote">
          <Text>{guestNote}</Text>
        </Panel>
      )}
    </Collapse>
  );
};

export default NotesAccordion;
