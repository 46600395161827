import { useContext } from 'react';

import { ApiContext, IApiClients } from 'src/providers/ApiProvider';

export const useApi = (): IApiClients => {
  const ctx = useContext(ApiContext);

  if (!ctx) {
    throw new Error('useApi requires an ApiContext');
  }

  return ctx;
};
