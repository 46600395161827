import { useAuth } from './useAuth';
import { IRoomDetails } from 'src/services/IRoomsClient';
import { useQuery, UseQueryResult } from 'react-query';
import { useApi } from 'src/hooks/useApi';

export const useRoom = (roomId: number): UseQueryResult<IRoomDetails, unknown> => {
  const { selectedHotelId } = useAuth();
  const { roomsClient } = useApi();

  return useQuery([selectedHotelId, 'rooms', roomId], () => roomsClient.getRoom(selectedHotelId, roomId));
};
