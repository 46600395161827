export class AuthError extends Error {
  private _type: string;
  private _code: number;

  constructor(type: string, code = 401, message?: string) {
    super(message);
    this._type = type;
    this._code = code;
  }
  public get type(): string {
    return this._type;
  }

  public get code(): number {
    return this._code;
  }
}
